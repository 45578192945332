.div_login {
    text-align: center;
    margin-bottom: 2rem;
    & img {
        width: 60%;
        margin: -15px 0;
    }
}
.div_login_V2 {
    text-align: center;
    margin-bottom: 2rem;
    & img {
        width: 60%;
        // margin: 2rem auto 2rem auto;
    }
}
.div_form_login {
    padding: 1rem 5rem;
}
.btn_register_header_main {
    width: 100%;
    background-size: 100% 100% !important;
    padding: 13px;
    border-radius: 50px;
    border: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.btn_login_header_main {
    width: 100%;
    background-size: 100% 100% !important;
    padding: 13px;
    border-radius: 50px;
    border: none;
    margin-top: 2rem;
}
.password_header_login {
    /* padding: 8px 5px; */
    padding-inline-start: 45px;
    border-radius: 1rem;
}
.password_header_login:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: #000;
}
.lock_icon_login {
    position: absolute;
    margin: 7px 15px;
    color: #000;
    width: 20px;
}
.telephone_header_login {
    padding-inline-start: 45px;
    border-radius: 1rem;
    border: #fcfcfc 1px solid;
}
.telephone_header_login:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: #000;
}
.phone_icon_login {
    position: absolute;
    margin: 7px 15px;
    color: #000;
    text-align: start;
    width: 20px;
}
.div_img_login_precenter {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px !important;
    padding-left: 15px !important;
    & img {
        width: 95%;
        position: absolute;
        bottom: 0;
    }
}
.div_close_modal_login {
    width: 100%;
    display: flex;
    justify-content: end;
    & svg {
        color: #fff;
        cursor: pointer;
        z-index: 9;
        width: 25px;
    }
}
@media only screen and (max-width: 768px) {
    .div_form_login {
        padding: 1rem 2rem;
    }
}