.div_header_main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0% 8% !important;
}
.div_header_sec1 {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    // max-width: 1140px;
    max-width: 1600px;
    margin: 0 auto;
}
.div_header_img {
    align-self: center;
    display: flex;
    justify-content: center;
    & img {
        height: 60px;
        width: auto;
        margin-top: 3px !important;
        cursor: pointer;
    }
}
.div_txt_prefix_domain {
    -webkit-text-fill-color: #0000;
    background: linear-gradient(180deg, #f2d638, #e9b033);
    -webkit-background-clip: text;
    background-clip: text;
    text-align: center;
    // color: #fff;
    text-align: left;
    text-wrap: nowrap;
    padding: 5px;
    display: flex;
    align-items: center;
    & p {
        margin: 0 auto;
        font-size: 28px;
    }
}
.txt_react_app_name_web {
    font-size: 18px;
    font-weight: 600;
}
.div_header_login_register {
    display: flex;
    & a {
        text-decoration: none;
        align-self: center;
        color: #fff;
        cursor: pointer;
    }
    & a:hover {
        color: #3161ff;
    }
    & div {
        text-decoration: underline;
        align-self: end;
        color: #fff;
        cursor: pointer;
    }
}
.btn_login_header {
    border: 2px solid #fff;
    padding: 5px 40px;
    border-radius: 20px;
    color: #fff;
    font-weight: 400;
    margin: 0rem .8rem;
}
.btn_register_header {
    padding: 5px 40px;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    height: 100%;
    align-items: center;
    font-weight: 400;
}
.ul_header_list {
    display: flex;
    margin: 0 auto;
    & li {
        list-style-type: none;
        margin-left: 5px;
        cursor: pointer;
    }
}
.img_header_list {
    height: 43px;
    transition: 0.1s ease;
    cursor: pointer;
}
.img_header_list:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 0.3s ease;
}
.div_ul_header_list_login {
    display: flex;
    align-items: center;
    // margin-left: 1rem;
    // background: #b41646;
    // border-radius: 50px;
    // width: fit-content;
    // font-size: 1.4rem;
    // padding: 5px;
    // align-items: center;
    // align-self: center;
    // & span {
    //     padding: 0px 16px 0px 16px;
    // }
}
.div_total_balance {
    margin-left: 1rem;
    background: linear-gradient(#DAEAE0 4%, #244125 20%, #244125 100%) 0% 0% no-repeat padding-box padding-box transparent;
    border-radius: 50px;
    width: fit-content;
    padding: 3px;
    align-items: center;
    align-self: center;
    & span {
        padding: 0px 16px 0px 16px;
        font-size: 16px;
        color: #fff;
    }
}
.div_total_balance_u789 {
    border: 2px solid #e5c45e;
    margin-left: 1rem;
    background: linear-gradient(#DAEAE0 4%, #031E40 20%, #011124 100%) 0% 0% no-repeat padding-box padding-box transparent;
    border-radius: 50px;
    width: fit-content;
    padding: 3px;
    align-items: center;
    align-self: center;
    & span {
        padding: 0px 16px 0px 16px;
        font-size: 16px;
        color: #fff;
    }
}
.div_total_balance_pok9 {
    border: 2px solid #e5c45e;
    margin-left: 1rem;
    background: linear-gradient(#DAEAE0 4%, #000 20%, #011124 100%) 0% 0% no-repeat padding-box padding-box transparent;
    border-radius: 50px;
    width: fit-content;
    padding: 3px;
    align-items: center;
    align-self: center;
    & span {
        padding: 0px 16px 0px 16px;
        font-size: 16px;
        color: #fff;
    }
}
.div_icon_setting {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    align-self: center !important;
    position: relative;
    width: 100%;
    min-height: 1px;
}
.img_icon_setting {
    height: 28px;
    margin-left: -18px;
    cursor: pointer;
}
.div_icon_setting_sec1 {
    position: relative;
}
.div_drawer_setting {
    position: absolute;
    will-change: transform;
    top: 2rem;
    min-width: 15rem;
    padding: 0.5rem 0.8rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    left: -8rem;
}
.img_drawer_side_setting {
    width: 100%;
    cursor: pointer;
    transition: 0.1s ease;
}
.img_drawer_side_setting:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: 0.1s ease;
}
.div_role_login {
    font-size: 14px;
    text-wrap: nowrap;
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
}
.div_role_login:hover {
    color: #000;
}
@media only screen and (max-width: 768px) {
    .btn_login_header {
        padding: 5px 15px;
    }
    .btn_register_header {
        padding: 5px 15px;
    }
    .div_header_main {
        padding: 0% 2% !important;
    }
    .div_ul_header_list_login {
        margin-right: 0rem;
    }
    .div_drawer_setting {
        left: -13rem;
    }
    .ul_header_list {
        display: none;
    }
    .btn_register_header_main_m {
        width: 100%;
        background-size: 100% 100% !important;
        padding: 13px;
        border-radius: 50px;
        border: none;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-size: 17px;
        font-weight: 500;
    }
    .btn_login_header_main_m {
        width: 100%;
        background-size: 100% 100% !important;
        padding: 13px;
        border-radius: 50px;
        border: none;
        margin-top: 2rem;
        font-size: 17px;
        font-weight: 500;
    }
    .password_header {
        /* padding: 8px 5px; */
        padding-inline-start: 45px;
        border-radius: 1rem;
    }
    .password_header:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
        -webkit-text-fill-color: #000;
    }
    .lock_icon {
        position: absolute;
        margin: 7px 15px;
        color: #000;
        width: 20px;
    }
    .telephone_header {
        padding-inline-start: 45px;
        border-radius: 1rem;
        border: #fcfcfc 1px solid;
    }
    .telephone_header:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
        -webkit-text-fill-color: #000;
    }
    .phone_icon {
        position: absolute;
        margin: 7px 15px;
        color: #000;
        text-align: start;
        width: 20px;
    }
    .div_form_login_register {
        padding: 0px 3rem;
    }
    .div_header_main_sec2 {
        padding: 0.5rem 1rem 1.5rem 1rem;
        line-height: 10px;
        margin-top: 0.9rem;
    }
    .div_login_register_m {
        text-align: center;
        & img {
            width: 65%;
            height: auto;
        }
    }
    .div_login_register_MARANG365_m {
        text-align: center;
        & img {
            padding: 25px 0px 40px 0px;
            width: 65%;
            height: auto;
        }
    }
    .div_header_sec1_m {
        display: flex;
        justify-content: flex-end;
        & button {
            display: flex;
            align-items: center;
            border: 1px solid #fff;
            border-radius: 0.2rem;
            background: transparent;
            font-size: 12px;
            color: #fff;
            padding: 2px 7px;
            & span {
                margin-left: 3px;
            }
        }
    }
    .div_header_main_m {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        padding: 1% 3% !important;
    }
}