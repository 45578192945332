.container_main_marketing {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 5px 10px !important;
    min-height: 100vh;
    max-width: 1140px !important;
    padding-bottom: 5rem !important;
}

.box_container_marketing {
    line-height: 20px;
    padding: 40px 40px 20px 40px;
    background: transparent linear-gradient(180deg, #333333 0%, #0e0e0e 100%) 0% 0% no-repeat padding-box;
    border-radius: 1rem;
}

.img_logo_marketing {
    width: 60%;
    height: auto;
    display: flex;
    margin: 0 auto;
}

.grid_container_marketing {
    padding: 15px 15px 0px 15px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.div_menu_marketing {
    margin-bottom: 1.5rem;
    padding: 10px;
    background: linear-gradient(to right, #F7E1A8, #ff4007);
    display: flex;
    justify-content: space-between;
    border-radius: 1.5rem;
    align-items: center;

    & img {
        width: 40px;
        height: auto;
    }
}



@media only screen and (max-width: 768px) {

    .grid_container_marketing {
        font-size: 16px;
    }

    .box_container_marketing {
        padding: 5px;
    }
}