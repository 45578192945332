.V5_container {
  padding: 0 10px;
}
.div_body_img_lobby {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background-size: cover;
}
.div_head_box {
  display: flex;
  align-items: end;
  border: white 2px solid;
  background: #ffffffe6;
  padding: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem #00000013 !important;
  border-radius: 0.25rem;
  & svg {
    font-size: 30px;
  }
  & h4 {
    font-size: 20px;
    margin-bottom: 0px;
    margin-left: 8px;
  }
}
.div_manage_pass {
  display: flex;
  align-items: center;
  & svg {
    font-size: 30px;
  }
  & span {
    font-size: 18px;
    margin-bottom: 0px;
    margin-left: 8px;
  }
}
.div_setting_user {
  padding-right: 15px;
}
.div_setting_telephone {
  padding-left: 15px;
}
.div_btn_chang_pass {
  display: flex;
  align-items: center;
}
.btn_submit3 {
  background: linear-gradient(90deg, #3285ff, #3285ff);
  width: 100%;
}
.div_img_bank {
  display: flex;
  justify-content: center;
}
.img_bank {
  width: 50%;
}
.container_loader {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.txt_loader h1 {
  color: #f2d638;
  font-size: 16px;
  font-weight: 400;
  margin: 2px auto;
}
.loading {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .line_box {
  padding: 2px;
  width: 226.3083451202px;
  // height: 20px;
  border: 2px solid #ffc107;
  border-radius: 20px;
}
.loading .line_box .line {
  width: 226.3083451202px;
  height: 16px;
  color: #f2d638;
  border: 2px solid;
  position: relative;
  filter: drop-shadow(0 0 10px #f2d638);
  border-radius: 20px;
  background: #f2d638;
  animation: loading 2s forwards cubic-bezier(0, 0, 0, 0);
}
// LOADING CLOCK
.clock {
  border-radius: 70px;
  border: 3px solid #fff;
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* margin-left: -50px; */
  /* margin-top: -50px; */
  display: block;
  width: 50px;
  height: 50px;
}
.clock:after {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 6px;
  left: 48%;
  height: 16px;
  width: 4px;
  border-radius: 5px;
  transform-origin: 50% 97%;
  animation: grdAiguille 2s linear infinite;
}
.clock:before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 9px;
  left: 47%;
  height: 13px;
  width: 4px;
  border-radius: 5px;
  transform-origin: 50% 94%;
  animation: ptAiguille 12s linear infinite;
}
.row_jk_wheel {
  display: flex;
  margin-top: 0.5rem;
  justify-content: center !important;
}
.box_wrapper {
  position: absolute;
  width: 80vw;
  max-width: 445px;
  height: 80vw;
  max-height: 445px;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
}

.inner_wrapper {
  position: absolute;
  width: 100%;
  inset: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.wheel_frame {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.div_center_wheel {
  position: absolute;
  width: 5rem;
  z-index: 5;
}

.gTLZXx {
  display: none !important;
  ;
}

.div_wheel_btn_back {
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
}

.btn_back_wheel {
  background: #6c757d;
  border: none;
}
.btn_wheel {
  border: none;
  border-radius: 0.5rem;
}
.btn-fixed2-active {
  width: 70px !important;
  height: 70px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  border: 0px !important;
  color: #fcfcfc !important;
  background: linear-gradient(to right, #ff3dd1, #440087);
  background: -webkit-linear-gradient(90deg,#9733ee,#da22ff);
}

.btn-fixed2-active-noborder {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #e3acf9 !important;
  color: white !important;
}

.btn-fixed2-active-noborder:hover,
.btn-fixed2-active-noborder:focus,
.btn-fixed2-active-noborder:active {
  background-color: #2d033b !important;
}

.col-20percent {
  display: block;
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.btn-fixed {
  width: 70px !important;
  height: 70px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  background: linear-gradient(#8d8d8d 0%, #000000a6 100%);
  border: 0px !important;
  color: #fff !important;
}

.btn-fixed:hover,
.btn-fixed:focus,
.btn-fixed:active {
  background-color: #0096e3 !important;
  color: #fff !important;
}

.btn-fixed-success {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #b19335;
  border: 0px !important;
  color: white !important;
  border: 3px solid #00a1ff !important;
}

.btn-fixed-success:hover,
.btn-fixed-success:focus,
.btn-fixed-success:active {
  background-color: #b19335 !important;
}

.btn-fixed2 {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #828387 !important;
  border: 0px !important;
  color: white !important;
}

.border-active {
  border: 4px solid #6f1ab6 !important;
}

@media only screen and (max-width: 768px) {
  .row_jk_wheel {
    margin-top: 0.25rem !important;
  }
  .div_setting_telephone {
    padding-left: 0px;
  }
  .div_setting_user {
    padding-right: 0px;
  }
}