.img_w100_h100 {
    width: 100%;
    height: 100%;
    max-width: 100%;
}
.div_img_bank_withdraw {
    min-width: 30px;
    width: 30px;
}
.div_img_bank_withdraw img {
    border-radius: 50%;
}
.btn_hitstory_deposit {
    background: linear-gradient(#ffffff, #ffffff);
    color: #f1bb09;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, .25));
    border-radius: 4px;
    padding: 5px 10px;
}
.btn_hitstory_deposit span {
    margin-left: 5px;
    font-size: 14px;
}
.div_bank_withdraw {
    display: flex;
    align-items: center;
    color: #fff;
    background: #000;
    padding: 5px 10px;
    border: 1px solid #fff;
    border-radius: 1rem;
}
.spn_fname_lastname {
    margin-left: 1rem;
}
.div_container_infomation {
    background: #fff;
    border: 1px solid #fff;
    padding: 15px;
    border-radius: 0.5rem;
    color: #000;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.0745098039) !important;
}
.div_sec_indomation_withdraw {
    & h2 {
        font-size: 16px;
    }
    & .p_credit {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
    & .p_turn {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }
}
.div_icon_withdraw {
    display: flex;
    justify-content: end;
    & img {
        width: 5rem;
    }
}
.btn_withdraw {
    width: 100%;
    background: #009f55;
    border: none;
    border-radius: 0.5rem;
}
.div_txt_footer_withdraw {
    color: #000;
    text-align: center;
    & .p_1 {
        font-size: 12px;
    }
    & .p_2 {
        font-size: 14px;
        display: flex;
        justify-content: center;
        & span {
            margin-left: 5px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}