.div_register_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    background-size: cover;
}
.div_register_container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    z-index: 1;
    position: relative;
    margin-top: 4.5rem;
    min-height: 100vh;
}
.div_register_sec1 {
    transition: all 0.3s;
    margin-bottom: 3rem !important;
}
.div_register_content_sec1 {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    & img {
        width: auto;
        height: auto;
        max-height: 55px !important;
        cursor: pointer;
    }
    & div {
        font-size: 25px;
    }
}
.div_register_content_sec2 {
    margin-top: 0.8rem;
    background: #fff;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
}
.div_register_content_body {
    margin-top: 0.8rem;
}
.div_btn_register_through_admin {
    display: flex;
    justify-content: center;
}
.btn_register_through_admin {
    & svg {
        width: 30px;
        height: auto;
    }
    & span {
        margin-left: 5px;
        font-size: 18px;
        font-weight: 300;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    text-align: center;
    width: 50%;
    box-shadow: -1px 2px 5px black;
    border-radius: 10px;
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.btn_register_through_admin:hover {
    background: #108d2d;
}
.strike-center {
    text-align: center;
    position: relative;
}
.strike-center .--content {
    background: transparent linear-gradient(270deg, #FEFC74 0%, #C67B36 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
    color: white;
    padding: 3px 49px !important;
    z-index: 4;
    position: relative;
}
.strike-center:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    height: 0;
    width: 100%;
    margin-top: -1px;
    border-bottom: 3px solid red;
    z-index: 3;
}
.strike-center::after {
    border-bottom: 3px #CA833A solid !important;
}
.div_register_fn {
    margin-top: 2rem;
}
.btn_chk_register_v2 {
    margin-top: 1rem;
    width: 100% !important;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    height: auto;
    border-radius: 0.25rem;
    color: #fff;
    border: transparent;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.btn_chk_register_v2 {
    background-image: linear-gradient(to right,
            #25aae1,
            #4481eb,
            #04befe,
            #3f86ed);
    box-shadow: 0 2px 5px 0 #4184eabf;
}
.btn_chk_register_v2:hover {
    background-image: linear-gradient(to right,
            #0da9ec,
            #2976fa,
            #0da9ec,
            #2976fa);
    box-shadow: 0 2px 5px 0 #0d6effbf;
}
.btn_submit_register_v2 {
    margin-top: 0.5rem;
    width: 100% !important;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    height: auto;
    border-radius: 0.5rem;
    color: #fcfcfc;
    border: transparent;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.btn_submit_register_v2 {
    background-image: linear-gradient(to right,
            #0ba360,
            #3cba92,
            #30dd8a,
            #2bb673);
    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}
.btn_submit_register_v2:hover {
    background-image: linear-gradient(to right,
            #00bb6a,
            #2ed39f,
            #00bb6a,
            #2ed39f);
    box-shadow: 0 4px 15px 0 rgba(13, 216, 132, 0.75);
}
.alert_des {
    background-color: transparent !important;
    color: #ff0018 !important;
    text-align: left;
    align-items: left;
    padding: 0px !important;
    align-items: center;
}
.swal2-container {
    z-index: X;
}
@media only screen and (max-width: 768px) {
    .btn_register_through_admin {
        width: 100%;
    }
}