.div_game_home_container {
    margin-top: 5rem;
    margin-bottom: 3rem;
}
.div_game_title_provider_home {
    display: flex;
    justify-content: center;
}
.div_game_title_provider_home_sec1 {
    background: #FFCC33 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    color: #fff;
    width: 30%;
    text-align: center;
    padding: 3px;
    font-size: 18px;
}
.div_game_title_provider_home_sec2 {
    background: #FF33B8 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    color: #fff;
    width: 30%;
    text-align: center;
    padding: 3px;
    font-size: 18px;
}
.div_game_title_provider_home_sec3 {
    background: #0dcaf0 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    color: #fff;
    width: 30%;
    text-align: center;
    padding: 3px;
    font-size: 18px;
}
.div_game_title_provider_home_sec4 {
    background: #20c997 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    color: #fff;
    width: 30%;
    text-align: center;
    padding: 3px;
    font-size: 18px;
}
.grid_game_home_sec1 {
    position: relative;
    background: #ffffff78;
    border: 2px solid #FFCC33;
    border-radius: 20px;
    margin-top: 0rem !important;
}
.grid_game_home_sec2 {
    position: relative;
    background: #ffffff78;
    border: 2px solid #FF33B8;
    border-radius: 20px;
    margin-top: 0rem !important;
}
.grid_game_home_sec3 {
    position: relative;
    background: #ffffff78;
    border: 2px solid #0dcaf0;
    border-radius: 20px;
    margin-top: 0rem !important;
}
.grid_game_home_sec4 {
    position: relative;
    background: #ffffff78;
    border: 2px solid #20c997;
    border-radius: 20px;
    margin-top: 0rem !important;
}
.div_img_game_home_sec1 {
    & img {
        width: 100%;
        border-radius: 1.5rem;
        padding: 15px;
        cursor: pointer;
        transition: 0.1s ease;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_game_home_sec2 {
    margin-top: 1.5rem;
}
.div_game_list_from_provider_container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    margin-bottom: 5rem;
    margin-top: 5rem;
}
.div_game_list_from_provider_back {
    line-height: 20px;
    padding: 10px;
    background: #27cd96 0% 0% no-repeat padding-box;
    font-size: 20px;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    display: flex;
    align-items: center;
    & svg {
        cursor: pointer;
    }
    & span {
        cursor: pointer;
        font-size: 16px;
    }
    & span:hover {
        color: #000;
    }
}
.div_img_provider_title {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0rem;
    margin: 1rem 0rem;
    & img {
        height: 105px;
        overflow: hidden;
        color: #fff;
        margin-bottom: 10px;
    }
    & p {
        font-size: 1.9rem;
        font-weight: 600;
        color: #fff;
        -moz-text-align-last: center;
        text-align-last: center;
        position: absolute;
        align-self: center;
        margin: 0 auto;
    }
}
.div_search_game_by_provider {
    display: flex;
    // padding: 0px 15px;
    & input {
        width: 95%;
        border-radius: 0.5rem 0rem 0rem 0.5rem;
        padding: 5px 0.75rem;
        animation: blink-animation-border 1s infinite;
        border: 2px solid transparent;
    }
}
.div_search_game_by_provider_icon {
    background: #fff;
    width: 5%;
    text-align: center;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    padding: 5px;
    margin-left: 1px;
    cursor: pointer;
    border: 1px solid #ced4da;
}
.div_search_game_by_provider_icon:hover {
    & svg {
        color: #fff;
    }
}
.grid_game_by_provider {
    // background: #ffffff78;
    // border: 2px solid #FFCC33;
    position: relative;
    background: rgba(0, 0, 0, .7);
    border-radius: 0.25rem;
    margin-top: 1rem !important;
}
.grid_game_by_provider::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 4px;
    background: linear-gradient(90deg, #00fdff, #9600ff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}
.grid_search_game_by_provider {
    // margin-bottom: 1rem !important;
}
.div_img_game_by_provider {
    & img {
        width: 100%;
        border-radius: 0rem;
        padding: 10px 10px 0px 10px;
        cursor: pointer;
        transition: 0.1s ease;
    }
    & img:hover {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
        transition: 0.1s ease;
    }
}
.div_txt_game_by_provider_main {
    margin-bottom: 0.5rem;
    padding: 0px 10px;
}
.div_txt_game_by_provider {
    position: relative;
    padding: 20px 15px;
    background: linear-gradient(90deg, #04042e, #185e93, #04042e);
    color: #fff;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
}
.div_txt_game_by_provider::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 4px;
    background: linear-gradient(90deg, #00fdff, #9600ff);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    border-radius: 2px;
}
// PATH GAME
.div_game_home_container_path_game {
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.div_game_title_provider_home_path_game {
    display: flex;
    justify-content: center;
}
.div_game_title_provider_home_sec1_path_game {
    // font-size: 2rem;
    // font-weight: 600;
    color: #fff;
    align-self: center;
    position: absolute;
    display: flex;
    & span {
        font-size: 25px;
    }
}
.div_game_title_provider_home_sec2_path_game {
    // font-size: 2rem;
    // font-weight: 600;
    color: #fff;
    align-self: center;
    position: absolute;
    display: flex;
    & span {
        font-size: 25px;
    }
}
.div_game_title_provider_home_sec3_path_game {
    // font-size: 2rem;
    // font-weight: 600;
    color: #fff;
    align-self: center;
    position: absolute;
    display: flex;
    & span {
        font-size: 25px;
    }
}
.div_game_title_provider_home_sec4_path_game {
    // font-size: 2rem;
    // font-weight: 600;
    color: #fff;
    align-self: center;
    position: absolute;
    display: flex;
    & span {
        font-size: 25px;
    }
}
.grid_game_home_sec1_path_game {
    position: relative;
    padding: 20px 15px;
    font-size: 30px;
    background: rgba(0, 0, 0, .6);
    border-radius: 15px / 37px;
}
.grid_game_home_sec1_path_game::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 8px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(90deg, #0a6b37, #0f2a17);
}
.grid_game_home_sec2_path_game {
    position: relative;
    padding: 20px 15px;
    font-size: 30px;
    background: rgba(0, 0, 0, .6);
    border-radius: 15px / 37px;
}
.grid_game_home_sec2_path_game::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 8px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(90deg, #954baa, #411d55);
}
.grid_game_home_sec3_path_game {
    position: relative;
    padding: 20px 15px;
    font-size: 30px;
    background: rgba(0, 0, 0, .6);
    border-radius: 15px / 37px;
}
.grid_game_home_sec3_path_game::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 8px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    background: linear-gradient(90deg, #d11925, #75090a);
}
.grid_game_home_sec4_path_game {
    position: relative;
    padding: 20px 15px;
    font-size: 30px;
    background: rgba(0, 0, 0, .6);
    border-radius: 15px / 37px;
}
.grid_game_home_sec4_path_game::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 8px;
    background: linear-gradient(90deg, #165584, #142461);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}
.div_img_game_home_sec1_path_game {
    & img {
        width: 100%;
        border-radius: 1.5rem;
        padding: 10px;
        cursor: pointer;
        transition: 0.1s ease;
    }
    & img:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        transition: 0.1s ease;
    }
}
.div_game_home_sec2_path_game {
    margin-top: 1.5rem;
}
.div_game_title_provider_home_path_game {
    display: flex;
    justify-content: center;
}
.img_title_bg_path_game {
    height: 50px;
    overflow: hidden;
}
.img_title_path_game {
    width: 40px;
    height: auto;
    margin-right: 8px;
}
.div_game_home {
    margin-top: 1.5rem;
}
@media only screen and (max-width: 900px) {
    .div_game_list_from_provider_container {
        padding-bottom: 7rem;
    }
    .div_game_home_container_path_game {
        margin-top: 2rem;
    }
    .div_game_title_provider_home_sec4_path_game {
        & span {
            font-size: 16px;
        }
    }
    .div_game_title_provider_home_sec3_path_game {
        & span {
            font-size: 16px;
        }
    }
    .div_game_title_provider_home_sec2_path_game {
        & span {
            font-size: 16px;
        }
    }
    .img_title_path_game {
        width: 20px;
        height: auto;
        margin-right: 5px;
    }
    .div_game_title_provider_home_sec1_path_game {
        & span {
            font-size: 16px;
        }
    }
    .img_title_bg_path_game {
        height: 25px;
    }
    .div_search_game_by_provider {
        & input {
            width: 90%;
        }
    }
    .div_search_game_by_provider_icon {
        width: 10%;
    }
}