.img-affiliate {
    width: 50%;
}
.btn_receive_commission {
    width: 100%;
}
.div_copy_aff_id {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    width: 100%;
}
.div_copy_aff_id span {
    color: #000;
    font-size: 20px;
}
.grid_txt_aff {
    display: flex;
    justify-content: end;
    color: #000;
}
.txt_nowarp {
    text-wrap: nowrap;
}