.div_carousels_slice {
    max-width: 1600px;
    margin: 4.5rem auto auto auto;
}
.div_carousels_slice_game {
    max-width: 1600px;
    margin: 1rem auto auto auto;
}
.muhotdeal {
    text-transform: uppercase;
    background: linear-gradient(to left, #ffd149 10%, #c19100 30%, #ab8000 60%, #ffd042 100%);
    /* background-size: auto auto; */
    /* background-clip: border-box; */
    background-size: 200% auto;
    /* background-clip: text; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientm 1s linear infinite;
    /* display: inline-block; */
}
.muhotdealV2 {
    font-size: 14px;
    text-transform: uppercase;
    background: linear-gradient(to left, #dc3545 10%, #c10000 30%, #ab0000 60%, #ff4242 100%);
    /* background-size: auto auto; */
    /* background-clip: border-box; */
    background-size: 200% auto;
    /* background-clip: text; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientm 1s linear infinite;
    /* display: inline-block; */
}
@keyframes gradientm {
    100% {
        background-position: 200% center;
    }
}
@media only screen and (max-width: 768px) {
    .img_siper_slice {
        border-radius: 0.25rem;
    }
    .div_carousels_slice {
        margin-top: 0rem;
        // padding: 0px 10px;
    }
    .div_carousels_slice_game {
        display: none;
        margin-top: 0rem;
    }
}