.div_lotto_isnot_login_main {
    margin-bottom: 1rem;
}
.div_lotto_isnot_login_sec1 {
    position: relative;
    opacity: 1;
    transition: all 0.3s;
    min-height: 75vh;
    max-width: 1600px;
    margin: 0 auto;
    // position: relative;
    // opacity: 1;
    // transition: all 0.3s;
    // min-height: 75vh;
    // padding: 0% 8% !important;
    // margin: 0rem 0.5rem;
}
.div_lotto_isnot_login_content {
    padding-top: 1% !important;
    border-radius: 10px;
    position: relative;
    opacity: 1;
    transition: all 0.3s;
    min-height: 50vh;
    padding: 1rem;
    // margin: 10px;
    margin: 1rem 0rem 1rem 0rem;
}
.div_lotto_isnot_login_content_sec4 {
    padding-top: 1% !important;
    border-radius: 10px;
    position: relative;
    opacity: 1;
    transition: all 0.3s;
    min-height: 83vh;
    padding: 1rem;
    margin: 10px;
    margin-top: 1rem;
}
.div_lotto_isnot_login_content_sec5 {
    // padding-top: 1% !important;
    border-radius: 10px;
    position: relative;
    opacity: 1;
    transition: all 0.3s;
    min-height: 83vh;
    padding: 15px;
    margin: 10px;
    margin-top: 1rem;
}
.div_lotto_isnot_login_content_sec6 {
    position: absolute;
    overflow-y: scroll;
    left: 0;
    top: 0;
    right: 0;
    bottom: 10px;
}
.div_lotto_isnot_login_content_sec1 {
    padding: 0rem 1.5rem;
    & h2 {
        font-size: 25px;
    }
    & h1 {
        font-size: 20px;
    }
}
.div_lotto_isnot_login_content_newgame {
    padding: 0rem 1.5rem;
    & h2 {
        font-size: 25px;
    }
    & p {}
}
.div_lotto_top_isnot_login {
    cursor: pointer;
    & img {
        width: 100%;
        height: auto;
    }
}
.div_top_lotto_isnot_login_content_sec2 {
    padding: 0rem;
}
.div_lotto_isnot_login_content_sec2 {
    padding: 0rem 1.5rem;
}
.div_newgame_isnot_login {
    & img {
        width: 100%;
        height: auto;
        border-radius: 1.5rem;
    }
}
.grid_newgame_isnot_login_main {
    position: absolute;
    overflow-y: scroll;
    left: 0;
    top: 0;
    right: 0;
    bottom: 10px;
    min-height: 350px;
    margin-top: 3rem !important;
    padding: 0rem 1.5rem;
}
.grid_newgame_isnot_login_main::-webkit-scrollbar {
    width: 0px;
}
.grid_newgame_isnot_login_sec {
    position: relative;
}
.div_result_lotto_isnot_login {
    border-radius: 10px;
    // color: #fff;
    padding: 0.7rem;
    border: 2px solid #4fad6c;
}
.div_txt_lotto_result_isnot_login {
    text-align: center;
    padding: 5px;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    & h1 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0rem;
        padding: 2px;
    }
}
.div_txt_lotto_th_result_isnot_login {
    text-align: center;
    padding: 5px;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    & h2 {
        margin: 0 auto;
        font-size: 16px;
        font-weight: 500;
    }
}
.div_txt_lotto_th_result_isnot_login_sec1 {
    border: 1px solid #e7c255;
    border-radius: 0.5rem;
}
.div_txt_lotto_th_title_isnot_login {
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    padding: 5px;
    border-bottom: 1px solid #e7c255;
}
.p_txt_title_th_isnot_login {
    color: #fff;
    font-size: 12px;
    font-weight: 200;
    text-align: center;
    margin: 0 auto;
}
.div_txt_lotto_result_th_isnot_login {
    color: black;
    background: white;
    box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 5px;
    font-weight: 400;
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 16px;
    border: 1px solid #FED776;
    text-align: center;
}
.div_box_lotto_result_th_isnot_login {
    padding: 0.5rem 1rem;
}
.div_loop_lotto_result_isnot_login {
    color: #fff;
    margin: 0.5rem auto;
}
.div_txt_type_lotto {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
}
.img_lotto_th {
    height: 15px;
    width: auto;
    border-radius: 50%;
    padding: 1px;
}
.spn_lotto_th {
    margin-left: 5px;
}
.img_lotto {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    padding: 1px;
}
.spn_lotto {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 300;
}
.div_txt_lotto_result {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}
.div_txt_lotto_result_isnot_login_sec2 {
    text-align: center;
    padding: 5px;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    & p {
        margin: 0 auto;
        font-size: 16px;
        font-weight: 500;
    }
}
.grid_lotto_category_isnot_login {
    position: absolute;
    overflow-y: scroll;
    left: 0;
    top: 0;
    right: 0;
    bottom: 10px;
    min-height: 350px;
    margin-top: 3rem !important;
    padding: 0rem 1rem;
}
.grid_lotto_category_isnot_login::-webkit-scrollbar {
    width: 0px;
}
.mb_05 {
    margin-bottom: 0.5rem !important;
}
.div_content_game_new_mr365 {
    margin-top: 1rem;
}
.div_content_game_new_slotonly {
    margin-top: 1rem;
}
.div_game_new_mr365 {
    background: #050237;
    text-align: center;
    color: #fff;
    padding: 5px;
    border-radius: 1rem;
    font-size: 18px;
}
.div_game_new_st_vegas {
    background: linear-gradient(#e50016 0%, #db0606 50%, #f10017 80%, #ff3b00 100%);
    text-align: center;
    color: #fff;
    padding: 5px;
    border-radius: 1rem;
    font-size: 18px;
}
.div_game_new_bb98 {
    // background: linear-gradient(135deg, #c0c0c0 0%,
    //         #d3d3d3 30%,
    //         #daa520 70%,
    //         #b8860b 100%);
    // background: linear-gradient(#fadb45 0%, #ffffb7 50%, #fadb45 100%) 0% 0% no-repeat padding-box padding-box transparent;
    // background: linear-gradient(to right, #6300ab, #ce00ff);
    background: linear-gradient(to right, #501439, #8a00ab);
    text-align: center;
    color: #e9e9e9;
    font-weight: 500;
    padding: 5px;
    border-radius: 1rem;
    font-size: 18px;
    box-shadow: 2px 4px 6px #0000004d;
}
.img_game_new_mr365 {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.img_game_new_mr365:hover {
    transform: scale(1.2);
    transform-origin: center;
}
.div_lotto_isnot_login_main_footer {
    margin-top: 2rem;
}
.div_lotto_isnot_login_main_footer_sec1 {
    text-align: center;
    color: #fff;
    & h1 {
        font-size: 24px;
    }
    & p {
        margin-bottom: 0.5rem;
    }
}
.img_provider_home {
    margin: 1rem 0rem;
    width: 100%;
    padding: 0% 8%;
}
@media only screen and (max-width: 1200px) {
    // CONTENT TOP 
    .div_lotto_isnot_login_sec1 {
        padding: 0% !important;
    }
    // TOP LOTTO
    .div_lotto_isnot_login_content {
        min-height: 35vh;
    }
    // GAME
    .div_lotto_isnot_login_content_sec4 {
        min-height: 65vh;
    }
    .grid_newgame_isnot_login_main {
        min-height: 55vh;
    }
    // LOTTO
    .div_lotto_isnot_login_content_sec5 {
        min-height: 65vh;
    }
    .grid_lotto_category_isnot_login {
        min-height: 55vh;
        padding: 0rem 0.5rem;
    }
}
@media only screen and (max-width: 768px) {
    .div_content_game_new_slotonly {
        padding: 10px 0px;
    }
    .img_provider_home {
        padding: 0% 0%;
    }
    .div_lotto_isnot_login_main_footer {
        padding: 0px 10px;
    }
    .spn_lotto {
        font-size: 13px;
    }
    // LOTTO
    .div_lotto_isnot_login_content_sec5 {
        min-height: 80vh;
    }
    .grid_lotto_category_isnot_login {
        margin-top: 2.3rem;
    }
}